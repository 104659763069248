/* Leaderboard */

.leaderboard-hero{
    background-color: #406eb5;
}

.leaderboard-doe{
   border:2px solid white !important;
}
.leaderboard-bg{
    background:linear-gradient(180deg, rgba(216, 216, 216, 0.68) 0%, rgba(0, 0, 0, 0) 100%) ;
    padding: 30px;
} 

.leaderboard-search {
    padding-left: 40px;
 }
 .leaderboard-search-icon{
     font-size: 20px;
     position: absolute;
     left: 13px;
     top: 0%;
     height: 100%;
     display: flex;
     align-items: center;
 }
 
 .hover:hover{
     box-shadow: 0 0 16px rgba(33,33,33,.2); 
 }
 
 .leaderboard-end{
     background-color: rgba(167, 167, 249, 0.87);
 }

 .leaderboard-img{
    width:50px;
 }

 .copy{
    width:20px;
    height:20px;
 }

 .cards{
    transition: transform 0.3s ,box-showdow 0.3s;
    color: rgb(255, 255, 255);
    border-radius: 15px;
    background: rgba(1, 1, 1, 0.9);
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    /* backdrop-filter: blur(10px);  */
    /* -webkit-backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.5); */
    text-align: center;
    font-family: Arial, sans-serif;

}

.cards:hover{
    transform: scale(1.03);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.686);
}
.headings {
    background: rgba(7, 201, 244, 0.2);
}
.rowss{
    display: flex;
    transition: transform 0.3s ,box-showdow 0.3s;
    border-radius: 10px;

}
.bg-leaderboard{
    background: #0000008f !important; 
}
.rowss:hover{
    transform: scale(1.02);
    box-shadow: o 3px 10px black;
    background-color: #1e1e2f;
    color: black;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}
 