.hero-bg {
  background-color: #7f62f4;
}

.dashboard-position {
  top: 100px;
  right: 0px;
}

.dashboard-vd-bg {
  background-image: url(https://wbcomdesigns.com/wp-content/uploads/edd/2022/04/discussing-video-course.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0e121e;
  opacity: .6;

}

.hero-1 {
  font-size: 70px;
  font-weight: bold;
}

.box-hover:hover {
  background-color: #192335;
  box-shadow: 10px 10px 5px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px !important;
}

.badge {
  padding: 10px 20px;
  border-radius: 100px;
  background: #b966e721 !important;
  color: #b966e7 !important;
}

.theme-gradient {
  background: -webkit-linear-gradient(#2f57ef, #b966e7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg {
  background-color: rgb(240, 242, 255);
}

.bg-primary-opacity {
  padding: 10px 20px;
  border-radius: 100px;
  background: #2f57ef21 !important;
  color: #2f57ef !important;
}

.nav-item {
  background: rgb(0 0 0 / 4%);
}

.scroll {
  -webkit-animation: scroll 80s linear infinite;
  -moz-animation: scroll 80s linear infinite;
  animation: scroll 80s linear infinite;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.demo-bg {
  background: rgb(251 199 226 / 15%);
}

.hero-text {
  font-size: 60px;
  font-weight: bolder;

}

.border100 {
  border-radius: 100px;
}

.single-demo {
  position: relative;
  border-radius: 15px;
  transition: 0.7s cubic-bezier(0.26, 0.76, 0.3, 0.75);
}

.thumbnail {
  position: relative;
}

.mobile-view {
  position: absolute;
  right: -70px;
  bottom: -20px;
  z-index: 2;
  padding: 7px;
  padding-top: 16px;
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  transition: opacity 0.6s ease 0.12s, transform 0.4s ease;
  width: 152px;
  height: auto;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid #FFFFFF;
  backdrop-filter: blur(7.5px);
}

.top-circle-shape {
  position: absolute;
  width: 240px;
  top: -96px;
  height: 240px;
  right: -96px;
  margin: 0 auto;
  background-image: linear-gradient(90deg, #CFA2E8, #637FEA);
  opacity: 0.09;
  border-radius: 100%;
  box-sizing: border-box;
}

.rbt-shape-bg {
  position: absolute;
  top: 0px;
  z-index: -1;
  opacity: 0.3;
}

.map-image {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  width: 70%;
}

.customer-bg {
  background-image: linear-gradient(270deg, #9e77ff 0%, #4460ff 100%);
}

.customer-text {
  font-size: 60px;
  padding: 0 12%;
  font-weight: 700;
}

.cart-bg {
  background-image: linear-gradient(90deg, #8DA9FC 0%, #B48DD5 100%);
  border-radius: 15px;
}

.cart-bg2 {
  background-image: linear-gradient(180deg, #EEDEFD 0%, #FFFFFF 100%);
  border-radius: 15px;
}

.cart-bg3 {
  background-image: linear-gradient(218.15deg, #b966e7 0%, #2f57ef 100%);
  border-radius: 15px;
}

.inner {
  background-color: #273041;
  background-image: url('https://rainbowit.net/html/histudy/assets/images/splash/cta-01.png');
  background-position: center right;
  background-repeat: no-repeat;

  top: 60px;
  padding: 90px;
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

.college-stats h3 {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid green;
}

.position-md-absolute {
  position: absolute !important;
}

@media only screen and (max-width:992px) {

  .college-bg .right-0 {
    position: relative !important;
  }

  .position-md-absolute {
    position: relative !important;
  }

}

select {
  border: none;
  padding: 5px;
  outline: none;
}