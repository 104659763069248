/* imgs start */

.successful{
  height: 80px;
  
  
  }
  
  /* imgs ends */
  
  
  /* bg-img */
  
  .total{
    margin-top: -1000px;
  }
  
  
  .box-shadow {
      transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .hover-scale:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* .bg1 {
      background-color: #f9f9f9;
      padding: 10px;
      border-radius: 5px;
     
  } */
  
  /* .bg2 {
      background-color: #e7ffe7;
      border-radius: 5px;
  }
   */
  .copyb:hover {
      background-color: #f1f1f1;
      transition: background-color 0.2s ease;
  }
  
  .refer:hover {
      background-color: #007bff;
      color: #fff;
      transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  /* css */.refer-button {
    background-color: white;
    background-color: #0a6795;
    border: 2px solid #ccc;
    height: 45px;
    padding: 10px;
    font-size: 10px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    transition: all 0.4s ease; 
    position: relative;
    overflow: hidden;
  }
  
  .refer-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, #0d6efd 50%, #20c997 50%); 
    transition: all 0.4s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    z-index: 0;
  }
  
  .refer-button:hover {
    color: white; 
    border-color: #7a42f4;
  }
  
  .refer-button:hover::before {
    transform: translate(-50%, -50%) scale(1); 
    background: linear-gradient(45deg, #0d6efd 50%, #20c997 50%);
  }
  
  .refer-button span {
    position: relative;
    z-index: 1;
  }
  .button{   
      
      font-size: 12px;
  color: white;
  
  }
  
  
  
  /* refer code */
  
  
  /* Box animation */
  .animated-box {
      border: 2px solid transparent;
      border-radius: 15px;
      animation: borderAnimation 3s infinite, boxShadowAnimation 2s infinite alternate;
      transition: transform 0.3s ease-in-out;
    }
    
    .animated-box:hover {
      transform: scale(1.05);
    }
    
    /* Border animation */
    @keyframes borderAnimation {
      0% {
        border-color: #3498db;
      }
      25% {
        border-color: #e74c3c;
      }
      50% {
        border-color: #f1c40f;
      }
      75% {
        border-color: #2ecc71;
      }
      100% {
        border-color: #3498db;
      }
    }
    
    /* Box shadow animation */
    @keyframes boxShadowAnimation {
      0% {
        box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
      }
      100% {
        box-shadow: 0 0 20px rgba(46, 204, 113, 0.7);
      }
    }
    
    /* Icon spacing and responsive adjustments */
    .mr-2 {
      margin-right: 10px;
    }
    
    @media (max-width: 768px) {
      .animated-box {
        padding: 2rem 1rem;
      }
    
      .mr-2 {
        margin-right: 5px;
      }
    }
  
  
    /* top first boxs */
  
  
    .stat-box {
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .stat-box:hover {
      transform: translateY(-20px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    
    .successful {
      transition: transform 0.3s ease;
    }
    
    .stat-box:hover .successful {
      transform: scale(1.1);
    }
    .text-muted{
      margin-left:-100px;
    }


    .home {
      background-color: rgb(47, 28, 106);
      border: 2px solid transparent; 
      position: relative;
      overflow: hidden;
      border-radius: 50px; 
      animation: pulse-border 3s infinite linear; 
    }
    

    
    .fixed-chat-popup {
      position: fixed;
      right: 15px;
      bottom: 30px;
      z-index: 9999;
    }
    
    @keyframes border-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    
    @keyframes pulse-border {
      0%, 100% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.5), inset 0 0 5px  rgba(255, 255, 255, 0.5),;
      }
      50% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.5);
      }
    }
    