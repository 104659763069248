.smallblue{
    color:rgb(124, 178, 253);
    font-size: 13px;
}
.extrasmallblue{
    color:rgb(124, 178, 253);
    font-size:10px;
}
.green{
    color:rgba(9, 188, 128, 1);
}
.custum-progress{
    height:5px;
    
}
.boxes-stretch{
    align-content: stretch;
}

.text-color{
    color:rgba(209, 208, 208, 0.958);
}
.bluebackground{
    background-color:rgba(42, 51, 74, 1)
}
.rank{
    font-size:10px;
    margin-top:-10px;
}
.rank-min-height{
    min-height: 200px;

}
.border-bottom{
    border-bottom:5px solid rgba(9, 188, 128, 1);
}
.para{
    z-index: 2;
    top:10px;
    left:10px;
}
.violet-button {
    background-color: violet;
    border: none;
    color: white;
    margin-left: 20px;
  }
.first{
    position: relative;
}
.img1{
    z-index: 1;
    position: relative;
}
.green-button {
    background-color: rgb(75, 190, 75);
    border: none;
    color: white;
    margin-left: 20px;
  }
  .blue-button{
    background-color: rgb(139, 212, 234);
    border: none;
    color: white;
    margin-left: 20px;
  }
  .heading-bullet{
    width:3px;
    height:25px;
    margin-bottom:5px;
    margin-right:10px;
  }
  .overflow::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
 .box-container{
    background-color: rgba(38, 43, 58, 1);
 }