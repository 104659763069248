:root {
  --t-blue6: #04003f;
  --t-green1: #09bd80;
  --t-blue1: #0d6efd;
  --t-orange2: #ffad3b;
  --bg-light: white;
  --bg-dark: black;
  --p-color: #212529;
  --nav-item: white;
  --search-bg: rgb(207, 207, 207);
  --prime-title: white;
  --blue3: #3f51b5;
  --prime-description: rgb(255, 255, 255);
}
.reel-top{
  position:fixed;

  top: 20px;
  left: 0px;
  width: fit-content;
}
.reel-item video{
  object-fit: fill !important;
}
.empty-profile-section {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-title {
  text-shadow: 0px 0px 1px #272727;
}
.all-courses {
  background-image: url(https://skillsuprise.com/resources/images/all-courses-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.doubt-support {
  background-image: url(https://skillsuprise.com/resources/images/doubt-support.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.placement-section {
  background-image: url(https://skillsuprise.com/resources/images/all-courses-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.sofy {
  min-height: 200px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji" !important;
}
p {
  color: var(--p-color);
  margin-bottom: 0px !important;
}
/*titles*/
.sec-title {
  font-weight: bold;
  color: white;
}
/*navbar stylings*/
.navbar {
  position: fixed !important;
  top: 0px;
  z-index: 99;
  left: 0px;
  background-color: var(--bg-dark);
  height: fit-content;
}
.primary-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 100%;
}
.logo {
  height: 35px;
  width: auto;
}
.nav-item {
  color: var(--nav-item);
}
.course-search {
  height: 40px;
  border-radius: 10px;

  background-color: var(--search-bg);
}
.course-search input {
  padding-left: 10px;
  border: none;
  border-radius: 5px;
}
.btn-signup,
.btn-login {
  margin-left: 20px;
  height: 40px;
  border-radius: 0px;
  color: white !important;
  min-width: 100px;
  transition: 0.5s;
}
.btn-signup {
  background-color: var(--t-blue1) !important;
}
.btn-login {
  border: 1px solid white !important;
  border-color: var(--t-green1);
}
.btn-login:hover {
  transition: 0.5s;
  background-color: var(--t-green1) !important;
}
.btn-signup:hover {
  background-color: var(--t-green1) !important;
}
.btn:hover {
  border: transparent !important;
}
.MuiBottomNavigationAction-iconOnly {
  display: block !important;
}
.bottom-nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: white;
  height: 52px;
  z-index: 9998;
  box-shadow: 0px 0px 2px grey;
  width: 100%;
}
.bottom-nav p {
  font-size: 12.5px;
  color: grey;
}
.bottom-nav .active p,
.bottom-nav .active svg {
  color: rgb(24, 23, 23) !important;
}
.bottom-nav svg {
  color: grey;
}
/*home page css*/
.courses-crumb,
.contact-crumb {
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../icons/all-courses-crumb.svg");
}
.online-crumb {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://skillsuprise.com/resources/images/cover3.png");
}
.selfpaced-crumb {
  min-height: 50vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://skillsuprise.com/resources/images/cover3.png");
}
.sidebar-item img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.offline-crumb {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://skillsuprise.com/resources/images/cover2.png");
}
.specialization-crumb {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../icons/cover1.svg");
}

.hero {
  padding-top: 90px;
  min-height: 90vh;
  background-size: cover;
  background-image: url("../images/homepage/hero2.png");
}

.prime-title {
  font-weight: bold;
  color: var(--prime-title);
}
.font-weight-bold {
  font-weight: 500;
}
.primary-text {
  color: var(--t-blue1) !important;
}
.secondary-text {
  color: var(--t-green1) !important;
}
.prime-description {
  color: var(--prime-description);
}
/*pulse animation*/

.pulse-center {
  text-align: center;
}

.pulse {
  width: 70px;
  height: 70px;
  background: rgb(255, 55, 0);
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  animation: animate2 2s linear infinite;
}

@keyframes animate2 {
  0% {
    box-shadow:
      0 0 0 0 rgba(255, 0, 64, 0.7),
      0 0 0 0 rgba(255, 0, 64, 0.7);
  }

  40% {
    box-shadow:
      0 0 0 50px rgba(255, 0, 64, 0),
      0 0 0 0 rgba(255, 0, 64, 0.7);
  }

  80% {
    box-shadow:
      0 0 0 50px rgba(255, 0, 64, 0),
      0 0 0 30px rgba(255, 0, 64, 0);
  }

  100% {
    box-shadow:
      0 0 0 0 rgba(255, 0, 64, 0),
      0 0 0 30px rgba(255, 0, 64, 0);
  }
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.scroll-suggestion {
  margin-left: 50%;
}
.scroll-suggestion img {
  height: 30px;
  width: auto;
}
.slider-buttons img {
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px 3px grey;
}
.courses-container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}
.account-dp-edit {
  position: absolute;
  right: 3%;
  min-height: 30px;
  min-width: 30px;
  bottom: 5%;
  padding: 5px;
  border-radius: 50%;
  color: white;
  border: 0.5px solid rgb(181, 181, 181);
  background-color: rgba(0, 0, 0, 0.619);
}
.overflow-x {
  display: flex;

  overflow-x: scroll;
}
.overflow-x .connection {
  max-width: 250px;
  margin-right: 15px;
  min-width: 150px;
}
.read-image {
  max-height: 250px;
}
.bg-notes {
  background-image: url("https://skillsuprise.com/resources/images/bg-11.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cheat-cover {
  min-width: 120px;

  height: 180px;
}
.mock-test-bg {
  background-image: url("https://skillsuprise.com/resources/images/mocktests.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.dark-cover {
  background-image: url("https://skillsuprise.com/resources/images/Interview-Preparation.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tech-cover {
  background-image: linear-gradient(45deg, #0067ff, #46ff5b);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.interview-prep-cover {
  background-image: url("https://skillsuprise.com/resources/images/interview-prep-cover.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.benifit-item {
  background-image: url("https://skillsuprise.com/resources/images/hack-line2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hacking-line {
  background-image: url("https://skillsuprise.com/resources/images/hacking-line.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hacking-line2 {
  background-image: url("https://skillsuprise.com/resources/images/hack-line2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hacker-bg {
  background-image: url("https://skillsuprise.com/resources/images/hacker-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.courses-slider {
  width: 100%;

  display: flex;
  padding: 10px 5px;
}
.courses-container::-webkit-scrollbar,
.shorts-slider .overflow-x::-webkit-scrollbar {
  display: none;
}
/*course item**/
.course-item {
  border-radius: 10px;
}
.slider-arrow {
  min-height: 40px;
  min-width: 40px;
  border-radius: 25px;
  background-color: white;
  fill: #000000;
}
.videos-crumb {
  min-height: 200px;
  padding-bottom: 50px;
}
.slider-course-item {
  max-width: 300px;
  width: 300px;
  margin-right: 15px;
  min-width: 300px;
}
.course-item img {
  min-height: 150px;
  max-height: 150px;
  border-radius: 10px 10px 0px 0px;
}
.course-item .info {
  padding: 10px;
}
.course-item .title {
  margin-bottom: 0px;
}
.buttons-holder {
  width: 100%;
  display: flex;
  border-top: 0.5px solid lightgrey;
  justify-content: space-between;
}
.buttons-holder vr {
  width: 1px;
  background-color: lightgrey;
  height: 100%;
}
/*Features list*/
.feature-item {
  border-radius: 5px;
  align-items: center;
  background-color: var(--bg-light);
}
.features-list {
  padding-top: 80px;
  padding-bottom: 80px;
}
.feature-item img {
  margin-right: 10px;
  height: 50px;
  width: auto;
}
/*reviews page*/
.h-50px {
  height: 50px;
}
/*video item*/
.video-item {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #d1d1d1;
}
.gradient-blue {
  background-image: linear-gradient(to bottom right, #523ee8, #8070ff);
}
.red-gradient {
  background-image: linear-gradient(135deg, #8070ff, #f73859);
}
.gradient-green {
  background-image: linear-gradient(135deg, #523ee8, #00dcaf);
}
.op-0 {
  opacity: 0;
}
.crop-range input {
  border-top: 1px solid blue;
  border-bottom: 1px solid blue;
}
.zoom-range {
  background-color: blue;
}
.btn-upload {
  height: 48px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px dashed grey !important;
}
.video-item .info {
  padding: 7px;
}
.video-item img {
  border-radius: 5px 5px 0px 0px;
}
.register-cta {
  border-radius: 10px;
  background-color: #04003f;
}
.main {
  margin-top: 95px;
}
.shadow {

  background-color: var(--bg-light);
}
.text-dark {
  color: var(--bg-dark);
}
.price {
  margin-right: 20px;
}
.btn-register-course {
  width: 100%;
  height: 48px;
  font-size: 18px;
  border: none !important;
  color: white !important;
  border-radius: 0px !important;
  background-color: #0d6efd !important;
}
.course-action .prices {
  width: 110px;
}
.course-action {
  background-color: white;
  z-index: 9999;

  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  display: none;
  padding: 5px 15px;
}
.course-details .course-title {
  font-weight: bold;
}
.course-details .course-desc {
  font-weight: normal;
  color: lightgrey;
}
.course-details .chip {
  margin-right: 10px;
  font-weight: 100;
  background-color: lightgrey;
}
.course-details .chip h6 {
  font-weight: 500;
  color: rgb(49, 49, 49);
}

.yellow-cover {
  background-image: url(https://skillsuprise.com/resources/images/bg/yellow-cover.svg);
}
.course-details .arrow {
  height: 20px;
  margin-right: 10px;
}
.text-sec {
  color: lightgrey;
}
.bg-grey {
  background-color: #ebebeb;
}
.cta2 {
  border-radius: 10px;
  background-image: url(https://skillsuprise.com/resources/images/course/cta2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.userdp {
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  /* z-index: 999999999; */
  border-radius: 55px;
  box-shadow: 0px 0px 3px grey;
}
.user-info {
  margin-left: 5px;
}
.btn-continue {
  border: 1px solid var(--t-blue1);
  border-radius: 20px;
  color: var(--t-blue1);
  background-color: transparent;
  padding: 5px 15px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transition: 0.4s;
}
.btn-continue:hover {
  background-color: var(--t-green1);
  border: none;
  transition: 0.4s;
  color: white;
}
.mr-10 {
  margin-right: 10px;
}
.main-grid {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
}
.lg-grid-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.lg-grid-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.lg-grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.lab-card {
  border-radius: 5px;
  border-left: 4px solid red;
}
.sidebar ::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
}
.sidebar::-webkit-scrollbar-track {
  width: 5px;
}
.bottom-nav .MuiAvatar-root {
  height: 25px;
  width: 25px;
  margin-left: auto;
  margin-right: auto;
}

.css-1wlk0hk-MuiAvatar-root .grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.rewards-page-grid {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.badge-item {
  height: 150px;
}
.side-grid {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.dp-container {
  margin-top: -45px;
}
.connection-search {
  height: 45px;
  border: none;
  border-radius: 5px;
}
.connection-search input {
  border: none;
  padding-left: 10px;
}
.br-5 {
  border-radius: 5px;
}
.connection-cover {
  height: 80px;
  border-radius: 5px 5px 0px 0px;
  background-color: lightgreen;
}
.contact-cover {
  background-image: url(../images/contact-cover.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.connection-info .info {
  margin-bottom: 10px;
}
.connection .btns {
  margin-top: 15px;
}
.connection-action button {
  width: fit-content !important;
  border: none !important;
  position: absolute;
  top: 5px;
  padding: 0px !important;
  right: 7px;
}
.navbar .dropdown button {
  border: none !important;
}
.no-arrow button {
  border: none !important;
}
.navbar .dropdown button:active,
.navbar .dropdown button:focus {
  border: none !important;
  box-shadow: none !important;
}
.bottom-nav-items {
  margin-top: -5px !important;
}
.btn-course-action {
  color: white !important;
  background-color: #008fc3 !important;
}
.speaker-text {
  padding: 5px;
  height: 115px;
  margin-right: 10px;
  color: white;
  background-color: black;
}
.speaker {
  color: #b3b3b3;
  margin-top: 0px;
  margin-bottom: 0px !important;
  line-height: 15px;
}
.trainer-dp {
  height: 115px;
  width: 115px;
}
.c0 {
  background-color: #fff7f5;
  border-left: 2px solid red;
}
.benifit-item {
  position: relative;
  border-left: 3px solid blue;
  border-radius: 5px;
}

.vertical-timeline::before {
  background: #2196f3 !important;
}
.black-cta {
  position: left;
  top: 0px;
  background-image: url(../icons/black-cta.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.suggestions-column {
  padding-left: 20px;
  padding-right: 20px;
}
.c2 {
  background-color: #fff1e6;
  border-left: 2px solid red !important;
}
.mt-20 {
  margin-top: 20px;
}
.a0 {
  background-color: #ddf4ff;
  border-radius: 7px !important;
  border-left: 2.5px solid rgb(0, 166, 255) !important;
}
.a1 {
  background-color: #fff7e1;
  border-radius: 7px !important;
  border-left: 2.5px solid red;
}
.likes-group .MuiAvatar-root {
  height: 25px;
  width: 25px;
}
.contact-page-crumb {
  background-image: url(../icons/contact-page-crumb.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.course-suggestions .cover {
  height: 80px;
  width: 100px;
  margin-right: 10px;
}
.hack-green {
  color: lime;
}

.hack-green-light {
  color: #08bb08;
}
.lab-cover {
  background-image: url(https://skillsuprise.com/resources/images/lab-cover.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.a2 {
  background-color: #e0fffc;
  border-radius: 7px !important;
  border-left: 2.5px solid green;
}
.c1 {
  background-color: #f0f7fe;
  border-left: 2px solid rgb(0, 166, 255) !important;
}
.light-shadow{
  box-shadow: 0px 0px 2px #9d9d9d;
}
.profile .dropdown a {
  padding-top: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
}
.navbar .dropdown .dropdown-toggle::after,
.connection-action .dropdown-toggle::after {
  content: "" !important;
  display: none !important;
}
.profile .dropdown button {
  padding: 0px !important;
  border: none !important;
  margin-right: -10px;
}
.no-arrow .dropdown-toggle::after {
  content: "" !important;
  display: none !important;
}
.connection-info {
  margin-top: -50px;
  padding: 65px 7px 5px 7px;
}
.blue-bg {
  min-height: 40vh;
  position: left;
  background-size: cover;
  background-image: url(https://skillsuprise.com/resources/images/cover2.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

.contact-person {
  height: 80px;
  width: 80px;
  box-shadow: 0px 0px 2px grey;
  border: 0.5px solid lightgrey;
}

.mt-auto {
  margin-top: auto;
}
.h-max-content {
  margin-bottom: auto;
}
.bottom-60 {
  bottom: 60px;
  display: flex;
  z-index: 999999;
  justify-content: center;
}
.position-fixed {
  position: fixed;
}
.right-referral {
  position: absolute;
  right: 0px;
}
.max-vh-80 {
  max-height: 80vh !important;
}

.course-userdp {
  height: 120px;
  width: 120px;
  border: 0.5px solid lightgrey;
  border-radius: 5px;
}
.connection {
  height: fit-content;
  border-radius: 5px;
}
.avatar {
  width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 3px grey;
}
.connection .btn {
  border: 1px solid var(--t-blue1);
  background-color: transparent;
  color: var(--t-blue1);
  width: 100%;
  border-radius: 40px;
}
.h-fit {
  height: fit-content;
}
.auth-input input {
  height: 48px;
}
.workshop-crumb2 {
  background-image: url("https://skillsuprise.com/resources/images/workshops-crumb.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.text-sec {
  color: #373c3e;
}
.benifit-item {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 2px lightgrey;
}
.h-25px {
  height: 25px;
  width: auto;
}
.register-canvas {
  height: 100vh !important;
  width: 100%;
}
.internship-item p {
  font-size: 14px;
}
.w-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.w-circle h1 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.workshop-crumb {
  background-image: url("https://skillsuprise.com/resources/images/workshop_crumb.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.MuiFormControl-root input {
  min-height: 48px;
}
.min-vh-90 {
  min-height: 90vh;
}
.crumb {
  height: 150px;
}
.workshop-crumb {
  background-color: var(--t-blue1);
  height: 300px;
}
.workshop-info {
  margin-top: -150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-light);
}
.icon30 {
  height: 25px;
  width: auto;
}
.mr-15 {
  margin-right: 15px;
}
.post-content {
  height: fit-content;
  background-color: black;
}
.post-action {
  height: 26px;
  width: auto;
  margin-right: 20px;
}
.post-footer {
  padding: 10px;
}
.post-comment {
  height: 40px;
  border-top: 0.5px solid lightgrey;
}
.post-comment input {
  height: 100%;
  width: 100%;
  padding-left: 15px;
  border: none !important;
}
.post-comment .btn {
  text-decoration: none;
}
.post-item .dropdown-toggle::after {
  content: none !important;
  border: transparent !important;
}
.dropdown {
  margin: 0px !important;
}
.community-page ::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.mr-5 {
  margin-right: 5px;
}
.community-page {
  height: 90vh;
}
.feed-container {
  overflow-y: scroll;
  height: 90vh;
}
.post-item .btn-primary {
  --bs-btn-border-color: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: transparent;
}
.main {
  margin-top: 93px;
  padding-top: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.course-cover {
  height: 150px;
  width: 100%;
}
.course-content {
  box-shadow: 0px 0px 3px grey;
}
.course-content .info {
  padding: 10px;
}
.text-field input {
  min-height: 50px;
}
.min-vh-80 {
  min-height: 80vh;
}
.referral-suggestion {
  height: 250px;
}
.course-container .course-cover,
.slider-course-container .course-cover {
  border-radius: 5px 5px 0px 0px;
}
.register-canvas {
  z-index: 999999 !important;
}

.course-content {
  min-height: 320px;
  height: 320px;
  position: relative;
}
.course-content .buttons-holder {
  position: absolute;
  bottom: 0px;
}
.course-title {
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  color: #181818;
  font-weight: 500;
}
.course-price {
  color: orangered;
  margin-top: 5px;
}
.all-courses-bg {
  background-size: cover;
  background-repeat: no-repeat;

  background-image: url("https://skillsuprise.com/resources/images/all-courses-bg.jpg");
}
.course-container {
  min-height: 330px;

  border-radius: 5px;
}
.video-item:hover {
  box-shadow: 0px 0px 3px grey;
}
.enrolled-course-cover {
  height: 150px;
  width: 100%;
}
.next-prev:hover {
  fill: #0d6efd;
  border-radius: 50%;
  color: white;
}
.overflow-y {
  overflow-y: scroll;
  max-height: 90vh;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.crumb {
  height: 50px;
}
.quiz-option:hover {
  transition: 0.5s;
  color: white !important;
  background-color: #00c3ff;
}
.red-title {
  border-left: 3px solid red;
  padding-left: 10px;
}
.comment2 .comment-avatar {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}
.home-reviews {
  background-image: url(../icons/alumni-section-crumb.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.project-item {
  background-color: #3e4348;
}
.home-reviews2 {
  background-image: url(../icons/reviews-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.course-top-nav h6 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.course-top-nav {
  position: fixed;
  top: -70px;
  z-index: 9999999999;
  left: 0px;
  min-height: 65px;
}
.flex {
  display: flex;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.review-crumb {
  background-image: url(https://skillsuprise.com/resources/images/all-courses-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.review-img {
  height: 90px;
  width: 90px;
  margin-right: 10px;
  border-radius: 5px;
}
.course-top-nav-hide {
  top: -70px;
}
.course-top-nav-show {
  top: 0px;
}
.course-shortcuts .btn-register-course {
  width: 200px;
}
.course-shortcuts {
  height: 65px;
}
.course-shortcuts h6 {
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  transition: 0.5s;
  line-height: 65px;
  border-bottom: 5px solid transparent;
}
.course-shortcuts h6:hover {
  transition: 0.5s;
  color: rgb(0, 153, 255);
  border-bottom: 5px solid white;
}
.navbar-bg {
  background-color: var(--bg-dark);
}
.certification-bg {
  background-image: url(../icons/certification-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.alumni-item .package {
  color: rgb(255 187 0);
    font-size: 25px !important;
    background: black;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    padding-left: 15px;
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
    margin-bottom: 5px !important;
    font-weight: bolder;
}
.rev-item .shadow {
  box-shadow: 0px 0px 3px #afafaf !important;
}
.ceh-internship-bg {
  background-color: #000000ab;
  height: fit-content;
  border-left: 3px solid rgb(0, 226, 41);
}
.tools-bg {
  background-color: #edf6f7;
}
.process {
  background-color: #edf6f7;
}
.top-n100 {
  margin-top: -150px;
  margin-bottom: 400px;
}
.ceh-qr {
  margin-bottom: 280px;
  margin-top: 250px;
  background-color: #2c81f6;
}
.alumni-item {
  background-image: url("https://skillsuprise.com/resources/images/top-curve-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hacking-internship-bg {
  background-image: url("https://skillsuprise.com/resources/images/hacking.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.alumni-avatar {
  border-radius: 50%;

  max-height: 100px;
  width: 100px;
  z-index: 9;
  border: 1px solid grey;
  box-shadow: 0px 0px 4px grey;
  height: 100px;
}
.alumni-review p {
  font-size: 14px;
  text-align: center;
  color: rgb(34, 34, 34) !important;
  line-height: 23px;
}

.shimmer-courses-row {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 10px;
}
.shimmer-title {
  height: 20px;
  width: 100%;
}
.shimmer-tutor-name {
  height: 20px;
  width: 50%;
}

/*skimmer effect end*/

.divider {
  text-align: center;
}
.alumni-avatar img {
  max-height: 170px;
  margin-top: -70px;
  border-radius: 50%;
}
.quiz-option {
  padding: 10px;
  height: 45px;
  background-color: #87cefa33;
  border: 0.5px solid lightblue;
  border-radius: 50px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.side-option {
  padding: 10px;
  transition: 0.5s;
  border-left: 3px solid transparent;
}
.side-option:hover {
  transition: 0.5s;
  background-color: rgb(232, 232, 232);
  border-left: 3px solid blue;
}
.home-slider-item {
  min-height: 200px;
  max-height: 200px;
}
.sac-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.scrollbar-hidden::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.event-content {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 10px 20px;
  color: white;
  background-color: #000000a3;
}
.main-engagement {
  min-height: 200px;
}
.slider-cover {
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
}
.slider-course-item {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.left-blue-border {
  border-left: 3px solid blue;
  padding-left: 10px;
  padding-bottom: 0px;
}
.down-svg {
  border: 1px solid blue;
  padding: 5px;
  height: 30px !important;
  width: 30px !important;
  border-radius: 20px;
}
.left-red-border {
  border-left: 3px solid rgb(0, 255, 166);
  padding-left: 10px;
  padding-bottom: 0px;
}
.down-svg:hover {
  background-color: blue;
  color: white;
}
.prep-image {
  border-radius: 2px;
  width: 50px;
}
.prep-image-category {
  max-height: 170px;

  height: 170px;
  border-radius: 50%;
  width: 100%;
}
.mb-18 {
  margin-bottom: 18px;
}
.border-left-green {
  border-left: 3px solid #009e55;
  padding-left: 10px;
  padding-bottom: 0px;
}
.desktop-stories .sac-item {
  min-width: 270px;
  max-width: 270px;
  min-height: 200px;
  max-height: 200px;
}
.slider-buttons svg {
  border:1px solid #0d2194;
  padding: 3px;
  border-radius: 50%;
  fill: #0d2194;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 35px;
  width: auto;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.slider-buttons svg:hover {
  fill: white;
  transition: 0.3s;
  background-color: #0d2194;
  color: white;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.overflow-x-scroll::webkit-scrollbar{

  height: 0px !important;
  width: 0px !important;
}
.alumni-item {
  height: 310px;
  width: 280px;
  text-align: center;
  min-width: 280px;
  background-color: #084298;
  margin-right: 20px;
  border-radius: 7px;
  padding-top: 20px;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  color: white;
}
.alumni-item img {
  height: 170px;
  width: 170px;
  border: 3px solid rgb(182, 182, 182);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.sac-item {
  position: relative;
  min-width: 200px;
  max-width: 220px;
  min-height: 150px;
  max-height: 150px;
  margin-right: 10px;

  border: 1px solid grey;
}
.active-side-option {
  padding: 10px;
  transition: 0.5s;
  background-color: rgb(232, 232, 232);
  border-left: 3px solid blue;
}
.nav-dropdown {
  position: relative;
  display: inline-block;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-dropdown-content a {
  font-size: 16px !important;
}
.nav-dropdown-content {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 250px;
  top: 25px;
  left: -15px;
  padding: 10px;
}

.modal-header svg {
  cursor: pointer;
}
.alumni-item {
  border-bottom: 5px solid #ffa600;
}
.nav-dropdown-content .nav-item {
  min-width: fit-content !important;
  width: 100% !important;
  margin-bottom: 10px;
}
.nav-dropdown-main {
  min-width: fit-content;
}
.nav-dropdown:hover .nav-dropdown-content {
  display: block;
}
.nav-dropdown-content:hover {
  display: block;
}
.side-option h6 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.chapters-col a {
  max-height: 50px !important;
  padding: 10px !important;
  height: 50px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
}
.video-cover-container img {
  min-height: 100px;
}
.side-option-active {
  background-color: rgb(221, 221, 221);

  border-left: 3px solid blue;
}
.payment-item {
  border-radius: 5px;
}
.post-option,
.post-option p {
  color: #a6a1a1;
  cursor: pointer;
}
.nav-item:hover {
  color: white !important;
}
.login-bg {
  background-image: url(../icons/login-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.nav-dropdown-content .nav-item:hover {
  color: var(--t-blue1) !important;
}
.br-4 {
  border-radius: 4px 4px 0 0;
}
.contact-page-crumb .contact-page-light p {
  color: white !important;
}
.contact-page-crumb .contact-page-light {
  color: white !important;
}
.nav-dropdown-content .nav-item p:hover {
  color: var(--t-blue1) !important;
}
.nav-dropdown-content {
  text-align: left !important;
}
.nav-item:hover p {
  color: white !important;
}
.btn-workshop-register {
  color: white !important;
  background-color: #ff1f0f !important;
}
.nav-item p {
  padding-bottom: 0px;
  color: #e9e9e999 !important;
  margin-top: -2px;
}
.hero .btn-warning {
  font-weight: 500 !important;
}

.nav-item {
  text-align: center;
  font-size: 13px !important;
  color: #e9e9e999;
}
.feature-option {
  border-radius: 5px;
  border: 1px solid blue;
  background-color: transparent;
}
.feature-option:hover {
  color: white;
  background-color: #00cb86;
}
.justify-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.message .extra-small {
  color: rgb(171, 169, 169) !important;
}
.message p {
  color: white !important;
}
.message {
  border-radius: 10px;
  padding: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 85%;
  color: #fff !important;
  background-image: linear-gradient(45deg, #9c27b0, #4551e5);
}
.post-content img {
  min-height: 250px;
}
.payment-image {
  height: 120px;
  width: 120px;
}
.lg-p-3 {
  padding: 1rem !important;
}
.syllabus .accordion-body {
  padding: 8px !important;
}
.syllabus ul {
  padding-left: 0px;
}
.quiz-category {
   border: 1px solid rgb(186 186 186); 
  border-radius: 5px;
  margin-bottom: 15px;
  /* box-shadow: 0px 3px 10px rgb(217, 217, 217); */
  transition: 0.3s;
}
.quiz-category:hover {
  box-shadow: 0px 3px 25px rgb(190, 190, 190);
}

.lg-mt-10 {
  margin-top: 10px;
}
.topic-item-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.w-60 {
  width: 60% !important;
}
.classes-cover {
  min-height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.text-12 {
  font-size: 12px;
}
.active-suggestion {
  background-color: lightgrey;
}
.morning {
  background-image: url("https://skillsuprise.com/resources/images/evening.png");
}
.afternoon {
  background-image: url("https://skillsuprise.com/resources/images/afternoon.png");
}
.evening {
  background-image: url("https://skillsuprise.com/resources/images/morning.png");
}
.pass-registration {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: none;
  z-index: 99999;
  height: 50px;
  background-color: white;
  padding: 5px 15px;
  box-shadow: 0px 2px 2px grey;
  width: 100%;
}
.video-cover-container {
  display: flex;
  align-items: center;

  position: relative;
}
.student-pass-cta {
  background-image: url("https://skillsuprise.com/resources/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.free-bg {
  background-image: url("https://skillsuprise.com/resources/images/free-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.btn-register-course {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.footer-social {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.video-content {
  padding-left: 20px;
  padding-right: 20px;
}
#suggestions-content {
  overflow-y: scroll;
}
@media only screen and (max-width: 1090px) {
  .nav-items {
    max-width: 600px !important;
    min-width: 500px !important;
  }
}
.inputs input {
  padding: 5px !important;
}
.course-progress-cover {
  min-height: 100px;
}
.bg9 {
  background-color: #0d6efd;
}
.duration-count {
  position: absolute;
  bottom: 7px;
  right: 10px;
  background-color: #00000094;
  padding: 1px 5px !important;
  border-radius: 2px;
  color: white;
}
.duration-count p {
  padding: 0px;
  color: #7a7777;
  margin: 0px;
  font-size: 13px !important;
}
@media only screen and (max-width: 1199px) {
  .nav-items {
    max-width: 650px !important;
    min-width: 550px !important;
  }
}

.profile {
  border: 1px solid lightgrey;
}
.link-item {
  color: #373c3e;
}
.class-item {
  border-left: 3px solid var(--t-green1);
}
.bg-theme-gradient {
  background-image: linear-gradient(316deg, #00e3db, #0d6efd);
}
.nav-items {
  max-width: 750px;
  min-width: 700px;
}
.mt-10 {
  margin-top: 10px;
}
.search-container {
  border-radius: 0px 0px 10px 10px;
}
#suggestions-content {
  padding-left: 25px;
  padding-right: 25px;
}
.media-controls {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-workshop-register {
  height: 45px;
  min-width: 300px;
}
.search-container input {
  height: 48px;
}
.course-tabs .MuiBox-root {
  padding: 0px;
}
.home-reviews .userdp {
  z-index: 9 !important;
}
.truncate {
  -webkit-box-orient: vertical;
  padding-bottom: 0px;
  display: -webkit-box;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}
.truncate2 {
  -webkit-line-clamp: 2;
}
.truncate9 {
  -webkit-line-clamp: 9;
}
.truncate1 {
  -webkit-line-clamp: 1;
}
.extra-small {
  font-size: 13px;
}
.truncate3 {
  -webkit-line-clamp: 3;
}

.truncate6 {
  -webkit-line-clamp: 3;
}
.dropdown-toggle::after {
  display: none !important;
}
.options-button button {
  padding: 0px !important;
  border: none !important;
}
.suggestion-cover {
  height: 110px;
  min-height: 110px;
  min-width: 130px;
  width: 130px;
}
.toast-container {
  position: fixed !important;
  left: 0px;
  bottom: 60px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.search-canvas {
  min-height: 100vh;
  z-index: 99999 !important;
}
.video-more-vert {
  position: absolute;
  right: 5px;
  padding: 2px;
  border-radius: 50%;
  background-color: #1b1b1b85;
  fill: white;
  color: white;
  top: 5px;
}
.subtitle {
  line-height: 30px;

  font-weight: normal;
}
.light-subtitle {
  color: lightgrey;
}
.pb-10 {
  padding-bottom: 10px;
}

.sidebar {
  width: 100%;
  position: fixed;
  height: 100vh;
  transition: 0.3s;
  top: 0px;
  z-index: 9999999999;
  padding: 0px;
  background-color: transparent !important;
}
.sidebar-content {
  width: 300px;
  margin-top: 65px;
background-color: white;
  z-index: 999999999999;
  background-color: white;
  height: 100%;
}
.sidebar-content .overflow-y {
  padding: 15px;
}
.badge-cover {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 63%);
}
.sidebar-item {
  padding: 10px;
  display: flex;
  width: 100%;
  border-left: 2px solid transparent;
}

.sidebar a {
  color: rgb(34, 33, 33);
  width: 100%;
}
.sidebar-item:hover {
  border-left: 2px solid var(--t-blue1);
  background-color: #f3ebeb;
}
.sidebar-item:hover .sidebar-item a {
  color: rgb(34, 33, 33);
}
.hide-sidebar {
  left: -150%;
  transition: 0.3s;
}
.display-sidebar {
  left: 0px;
  transition: 0.3s;
}
.bg-theme-green {
  background-color: #09bd80;
}
.auth-icon {
  height: 70px;
  width: 70px;
  margin-right: 15px;
}
.quiz-option {
  transition: 0.5s;
}
.sidebar-close{
border: 1px solid black;

box-shadow: 0px 0px 3px black;}
.search-suggestions {
  position: absolute;
  left: 0px;

  top: 48px;
  min-width: 500px;
  background-color: white;
  padding: 10px;
}
.h-30 {
  height: 30px;
}
.mr-2 {
  margin-right: 5px;
}
.company-logo {
  height: 90px;
  width: 90px;
  margin-right: 15px;
}
.btn-darkgreen {
  background-color: #00a365 !important;
  color: white !important;
}
.benifit-item img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}
.section {
  margin-top: 15px;
  margin-bottom: 15px;
}
.btn-dark-grey {
  background-color: #607d8b !important;
  color: white !important;
}
.btn-theme-green {
  color: white !important;
  background-color: var(--t-green1) !important;
}
.mobile-timeline::-webkit-scrollbar {
  width: 0px;
  padding: 0px 10px;
}
.timeline::-webkit-scrollbar {
  width: 5px;
}
.timeline {
  background-color: transparent;
  padding: 0px 1px;
}
.click {
  cursor: pointer;
}
.class-item {
  transition: 0.5s;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #8c8888;
}
.class-title {
  color: var(--blue3);
}

.class-item p {
  color: #373c3e;
}
.text-grey {
  color: #7a7a7a !important;
}
.class-item:hover {
  transition: 0.5s;
  box-shadow: 0px 0px 3px #0a0c0c !important;
}
.no-shadow {
  box-shadow: none !important;
}
.footer .text-grey {
  color: #a7a7a7 !important;
}

.main,
.content {
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  overflow-x: hidden;
}
.light-grey {
  color: lightgrey;
}

.bottom-nav .active {
  border-top: 3px solid black;
}
.ml-5 {
  margin-left: 5px;
}
.share-post-canvas {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999999999999999999;
  min-height: 100vh;
  height: 100vh;
}
.btn-upload {
  border: 1px dashed grey;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 300px;
  max-height: 400px;
  bottom: 0px;
}
.sidebar-content {
  position: relative;
}
.sidebar-close {
  position: absolute;
  top: 10px;
  right: -60px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  border-radius: 28px;
  background-color: white;
  cursor: pointer;
}
.scroll-navigator .MuiChip-root {
  margin-right: 7px;
}
.overflow-x {
  overflow-x: scroll;
}
.text-align-left {
  text-align: left !important;
}
.cropper {
  max-height: 400px;
}
.cropper {
  min-height: 300px;
}
.MuiAvatar-circular {
  cursor: pointer;
  box-shadow: 0px 0px 2px grey;
}
.reactEasyCrop_Container {
  min-height: 250px;
  width: auto;
}
.editor-container {
  height: fit-content;
}
.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}
.shorts-slider::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.active-video {
  z-index: 9999;

  box-shadow: 0px 0px 5px grey;
}
.tutorials-crumb-container {
  background-color: black;
}
.videos-crumb {
  background-position: bottom;
  background-image: url("https://skillsuprise.com/resources/images/tutorials-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.active-video .holder {
  display: flex;
}
.account-dp {
  margin-top: -80px;
  position: absolute;
  bottom: -30px;
  left: 20px;
  background-color: white;
}
.setting-icon {
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
}
.setting-option {
  position: relative;
}
.play-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  color: white;
}
.cover-picture-edit-toggle {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  background-color: white;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
}
.quiz-cta {
  min-height: 250px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/icons/quiz-cta.svg);
}
.setting-option {
  color: rgb(24, 24, 24);
}
.holder {
  display: none;
  justify-content: space-between;
}
.right-0 {
  position: absolute;
  right: 0px;
}
.setting-icon svg {
  height: 40px;
  width: 40px;
}
.user-cover-container {
  height: 190px;

  background-color: black;
}
.bg-theme-yellow {
  background-color: #ffad01 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.btn-theme-yellow {
  background-color: #ffad01 !important;
}
.student-pass-features .shadow {
  text-align: center;
  box-shadow: none !important;
}
.pass-cover {
  background-image: url("https://skillsuprise.com/resources/images/pass-cover.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.student-pass-features img {
  height: 90px;
  width: 90px;
  margin-bottom: 15px;
}
.landing-search {
  height: 50px;
  width: 85%;
  position: relative;
  background-repeat: no-repeat;
  background-image: url(../icons/custom-search-bg.svg);
  background-size: 100%;
}
.landing-search input {
  border: none;
  position: absolute;
  left: 75px;
  top: 5px;
  width: 55%;
  color: grey;
  height: 36px;
}
.landing-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(56, 53, 53);
  opacity: 1; /* Firefox */
}
.custom-search-icon {
  height: 35px;
  width: auto;
}
.landing-search button {
  border: none;
  background-color: transparent;
}
.custom-search-button {
  height: 35px;
  width: auto;
  width: 120px;
  z-index: 999999;
}
.online-crumb hr {
  height: 2px;
  width: 50px;
  margin-right: 10px;
  background-color: white;
}
.lg-text-warning {
  color: #ffbf00;
}
.lg-grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.border-blue {
  border: 1px solid blue;
}
.lg-grid-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.learning-desc {
  border-left: 3px solid red;
  padding-left: 10px;
}
@media only screen and (max-width: 992px) {
  .md-grid-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md-grid-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md-grid-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md-grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 762px) {
  .sm-grid-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm-grid-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .sm-grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm-grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 567px) {
  .grid-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .grid-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
.popular-course:hover {
  transition: 0.3s;
  background-color: lightgray;
}
.media-container:hover .media-controls {
  transition: 1s;
  display: flex;
}
.media-controls {
  display: none;
}
.media-control {
  cursor: pointer;
  border-radius: 50%;

  height: 60px !important;
  width: 60px !important;
  color: rgb(241, 241, 241);
}
#video-container::-moz-progress-bar {
  height: 10px;
  background-color: red;
}

.suggestions-column button {
  width: 100% !important;
}
.lesson-action {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}
.dashed-border-grey {
  border: 1px dashed lightgrey;
  border-radius: 5px;
}
.bottom-right {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.lesson-suggestion {
  height: 100px;
  width: 120px;
  min-width: 120px;
}
.MuiTooltip-tooltip p {
  color: white;
}
.lg-mt-40 {
  margin-top: 40px;
}
.btn-sm {
  min-width: 130px;
}
.progress-icon {
  height: 25px;
  width: 25px;
}
.lg-mb-20 {
  margin-top: 20px;
}
.lg-mb-50 {
  margin-bottom: 50px;
}
.min-vh-70 {
  min-height: 70vh;
}
.bg1 {
  background-color: #e0bc35;
}
.progress-tracker .div {
  box-shadow: none !important;
}
.progress-tracker .MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 0px 3px grey !important;
}
.progress-tracker .MuiTimeline-root {
  padding: 6px 0px !important;
}
.bg2 {
  background-color: #20c8e5;
}
.bg3 {
  background-color: #45cf79;
}
.bg4 {
  background-color: #ea8340;
}
.bg5 {
  background-color: #031545 !important;
}
.course-options .text-center {
  padding: 10px;
  border-radius: 5px;
  color: white;
}



::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}
.course-options p {
  color: white;
}
.w-30 {
  width: 30%;
}
.bg-category {
  background-color: #031545;
}
.course-options img {
  border-radius: 5px;
}
.bg6 {
  background-color: #ff824d;
}
.bg7 {
  background-color: #06a2ff;
}
.bg8 {
  background-color: #e8027d;
}
.feature-1 {
  background-repeat: no-repeat;
  background-size: cover;
  position: top;
  background-image: url(https://skillsuprise.com/resources/images/feature-cover1.svg);
}
.video-slider {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
}
.media-container {
  transition: 0.5s;
}
.avatar {
  background-color: white;
  height: 80px;
  width: 80px;
  max-height: 80px;
  max-width: 80px;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tilt-in-fwd-tr {
  -webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.bounce-twice {
  animation: myAnim 2s ease 0s 1 normal forwards;
}
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-12 3:43:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.wallet-icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 30px;
  width: 30px;
}
.wallet-page .shadow {
  position: relative;
}

.slider-course-item {
  min-width: 270px;
  max-width: 270px;
  min-height: 220px;
  max-height: 220px;
  box-shadow: 0px 0px 3px grey;
  margin: 5px 10px 5px 0px;
}
.offline-bg {
  border-radius: 5px;
  color: white;
  text-shadow: 0px 0px 4px black;
  background-image: url("https://skillsuprise.com/resources/images/offline-bg.png");
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: black;
}
.online-bg {
  border-radius: 5px;
  color: white;
  background-color: black;
  text-shadow: 0px 0px 4px black;
  background-image: url("https://skillsuprise.com/resources/images/online-bg.png");
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.specialization-bg {
  border-radius: 5px;
  color: white;
  background-color: black;
  text-shadow: 0px 0px 4px black;
  background-image: url("https://skillsuprise.com/resources/images/specialization-bg.png");
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.selfpaced-bg {
  border-radius: 5px;
  color: white;
  background-color: black;
  text-shadow: 0px 0px 4px black;
  background-image: url("https://skillsuprise.com/resources/images/selfpaced-bg.png");
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.bg-image {
  border-radius: 5px;
  color: white;
  background-color: black;
  background-size: cover;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.mock-bg {
  background-image: url("../images/mock-bg.png");
}
.bg-contain {
  background-size: contain !important;
}
.coding-bg {
  background-image: url("../images/coding-bg.png");
}
.interview-bg {
  background-image: url("../images/interview-bg.png");
}
.quiz-bg {
  background-image: url("../images/quiz-bg.png");
}
.resource-bg {
  background-image: url("../images/resource-bg.png");
}
.test-bg {
  background-image: url("../images/test-bg.png");
}
.slider-course-item .slider-cover {
  height: 110px;
}
.text-orangered {
  color: orangered;
}
.placement-company {
  height: 60px;
  width: auto;
  margin-right: 30px;
}
.chat-input-window {
  position: absolute;
  bottom: -60px;
  left: 0px;
  width: 100%;
}
.wrapper {
  max-width: 100%;
  overflow: hidden;
}
.sidebar-options a {
  width: 100% !important;
}
.bottom-0 {
  bottom: 0px !important;
}

.setting-section {
  border-radius: 5px;
  border: 1px dashed rgb(212, 212, 251);
}
.scroll-vert {
  overflow-y: scroll;
  height: 76vh;
}
.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
}

.marquee img {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.h-70 {
  height: 70%;
}
.reference {
  height: 20px;
  width: 20px;
}
.max-vh-50 {
  max-height: 50vh;
  height: 50vh;
}
.option-button {
  height: 40px;
  width: 40px;
  margin: 2px;
}
.vh-90 {
  height: 90vh;
}
.min-vh-50 {
  min-height: 50vh;
}
.instructions li {
  margin-top: 10px;
}
.interview-prep {
  border-radius: 10px 10px 0px 0px;
  background-image: linear-gradient(165deg, #0745f5, #00e872);
}
.interview-prep-item {
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgb(217, 217, 217);
  transition: 0.3s;
}

.interview-prep-item:hover{
  box-shadow: 0px 3px 25px rgb(190, 190, 190);
}
.hover-active:hover {
  background-color: #008fc33d;
}
.job-alert-company-logo {
  height: 30px;
  width: auto;
}
.hr-line-blue {
  height: 5px;
  width: 200px;
  border-radius: 5px;
  background-color: rgb(0, 132, 255);
}
.hr-line-red {
  height: 5px;
  width: 200px;
  border-radius: 5px;
  background-color: #e7b0fb;
}
.offlineimage {
  border: 2px solid white;
  box-shadow: 0px 0px 2px white;
}
.rev-item {
  display: block;
  min-height: 300px;
}
.stories-bg {
  background-size: cover;
  background-repeat: no-repeat;
}
.gallery img {
  border: 4px solid #c5d2ea;
  transition: 0.5s;
}
.gallery img:hover {
  border: 4px solid #0088ff;
  transition: 0.5s;
}
.alumni-item p {
  white-space: normal;
}
.results-driven {
  border: 1px solid #c3dbff;
  border-radius: 10px;
  padding: 20px;
  background-color: #dff6e9;
}
.alumni-item:hover {
  transition: 0.5s;
  background-color: #08ad75;
  border-bottom: 4px solid blue;
}
.alumni-item:hover .alumni-item img {
  border-radius: 50% !important;
  transition: 0.5s;
}
.alumni-item {
  box-shadow: 0px 0px 5px black;
}
.mobile .sm-p-15 {
  padding: 0px !important;
}

.rev-item .shadow {
  border-bottom: 3px solid #0d6efd !important;
}
.rev-item .shadow::before {
  position: absolute;
  background-image: url(https://demo.themewinter.com/wp/turitors/learndash-classic/wp-content/uploads/sites/5/2020/02/feature_bg.png);
  background-repeat: no-repeat;

  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(37, 117, 252, 0);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
  background-size: 101% 101%;
}
.rev-item .shadow {
  position: relative;
}
.rev-item {
  color: white;
}
.rev-item p {
  color: white;
}
.rev1 {
  border: 2px solid blue !important;
}
.rev2 {
  background-color: #0acc86;
}
.rev3 {
  background-color: #fa4142;
}
.rev4 {
  background-color: #fa4142;
}
.slider-course-item .slider-cover {
  height: 140px !important;
}
.reels-cta {
  background-color: #02b97b !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://skillsuprise.com/resources/images/homepage/green-bg.png");
}
.alumni-container {
  padding-top: 50px !important;
}
.process .circle {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border: 3.5px solid rgb(4, 192, 73);
  display: flex;
  border-radius: 50%;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 16, 99);
}
.process .item {
  border: 0.8px solid rgb(0, 16, 99);
}
.process2 {
  background-color: #9e9e9e;
}
.h-10 {
  height: 10px !important;
}
.chapter-dropdown {
  color: black !important;
  text-align: left !important;
  border-radius: 3px !important;
  background-color: white !important;
  border: 1px solid var(--t-blue1) !important;
}
.chapter-dropdown:hover {
  color: black !important;
  text-align: left !important;
  border-radius: 3px !important;
  background-color: white !important;
  border: 1px solid var(--t-blue1) !important;
}
.syllabus-section {
  padding: 20px;
  border: 1px solid blue;
  border-radius: 10px;
}
.bl-item-1 {
  border-left: 3px solid red;
}

.bl-item-2 {
  border-left: 3px solid blue;
}

.bl-item-3 {
  border-left: 3px solid var(--t-green1);
}

.bl-item-4 {
  border-left: 3px solid rgb(255, 0, 153);
}

.bl-item-5 {
  border-left: 3px solid orangered;
}

.bl-item-6 {
  border-left: 3px solid rgb(0, 225, 255);
}

.bl-item-7 {
  border-left: 3px solid rgb(255, 247, 0);
}

.bl-item-8 {
  border-left: 3px solid red;
}

.bl-item-9 {
  border-left: 3px solid blue;
}

.bl-item-10 {
  border-left: 3px solid var(--t-green1);
}

.bl-item-11 {
  border-left: 3px solid rgb(255, 0, 153);
}

.bl-item-12 {
  border-left: 3px solid orangered;
}

.bl-item-13 {
  border-left: 3px solid rgb(0, 225, 255);
}

.bl-item-14 {
  border-left: 3px solid rgb(255, 247, 0);
}
.bl-item-15 {
  border-left: 3px solid red;
}

.bl-item-16 {
  border-left: 3px solid blue;
}

.bl-item-17 {
  border-left: 3px solid var(--t-green1);
}

.bl-item-18 {
  border-left: 3px solid rgb(255, 0, 153);
}

.bl-item-19 {
  border-left: 3px solid orangered;
}

.bl-item-20 {
  border-left: 3px solid rgb(0, 225, 255);
}

.bl-item-21 {
  border-left: 3px solid rgb(255, 247, 0);
}
.profile-info-add {
  border: 1px solid blue;
}
.journey-step {
  width: 300px;
  border-radius: 7px;
  margin-right: 20px;
}

#container9 ::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
}
#container9::-webkit-scrollbar-track {
  width: 5px;
}
#container9::-webkit-scrollbar {
  display: none;
}

.journey-slider .slider-arrow {
  background-color: #b4c4fc !important;
  transition: 0.5s;
}
.journey-slider .slider-arrow:hover {
  background-color: var(--t-blue1) !important;
  color: white !important;
}
.money-back img {
  height: 40px;
}

.MuiModal-root {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}
/*25-07-2023*/
.active {
  color: white !important;
}
.active a {
  color: white !important ;
}
.trainer-flow {
  height: 150px;
  margin-right: 20px;
}

.play-hacking {
  height: 70px;
  width: 70px;
  background-color: red;
}
.hacking-span {
  width: fit-content !important;
  background-color: green;
  padding: 1px 5px;
  margin-right: 3px;
  border-radius: 2px;
}
.text-desc {
  line-height: 27px;
  color: lightgrey !important;
}
.milestone-section {
  background-size: cover;
  min-height: 300px;
  background-repeat: no-repeat;
  background-color: #1591bd;
}
.referral-span {
  background-color: orangered;
}
.responsive-options {
  background-color: transparent !important;
  border: none !important;
}

@media only screen and (max-width: 1300px) {
  .d-13k-none {
    display: none !important;
  }
  .nav-items {
    max-width: 620px;
    min-width: 600px;
  }
}
@media only screen and (max-width: 992px) {
  .nav-items {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .nav-items {
    min-width: 200px !important;
    max-width: 200px !important;
  }
}
.w-fit {
  width: fit-content !important;
}
.text-small {
  font-size: 14px;
}
.cai-bg {
  background-image: url("https://skillsuprise.com/resources/images/cai-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.text-right {
  text-align: right;
}
.timetable-item {
  height: 100px;
  box-shadow: 0px 0px 2px grey;
  border-radius: 5px;
  border-left: 3px solid red;
}
.stat-icon {
  height: 40px;
}
.legal-section img {
  height: 60px;
}

.course-batch-chat {
  height: 60px;
  width: 60px;
  border-radius: 20px;
  position: fixed;
  bottom: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 3px black;
  padding: 7px;

  right: 30px;
  z-index: 999999;
}
.click {
  cursor: pointer;
}

.vh-85 {
  height: 85vh;
}

.vh-70 {
  height: 70vh;
}
.course-dash-options img {
  height: 35px;
  margin-right: 5px;
}
.video-suggestions {
  height: 80vh;
  padding-bottom: 20px;
  overflow-y: scroll;
}
.video-suggestions::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.video-suggestions:hover.video-suggestions::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.about-span span {
  font-size: 35px;
  font-weight: bold;
}
.circle {
  width: 50px;
  height: 50px;
  

  border-radius: 50%;
}

.class-green {
  color: white !important;
  background-color: blue !important;
  background: blue !important;
}
.react-calendar__tile--active{
background: lightblue !important;
}
.popover {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
}
.dashboard-placements .position-absolute{

  position: absolute;
  left: 5px;
  bottom: 5px;
  color: var(--t-orange2);
}
@media only screen and (max-width: 576px) {
  .courses-slider {
    overflow-y: hidden !important;
    display: block !important;
  }
  .fixed-chat-popup {
  bottom: 65px !important;
  
  }
  .features-list h5{
    font-weight: 700 !important;
  }
  .alumni-item img{
    height:100px;
    width: 100px;
  }
  .sm-p-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .alumni-item p {
    font-size: 14px !important;
  }
  .alumni-item{
    height:fit-content;
    min-width: 180px;
  }
  .alumni-item .package{
    font-size: 20px !important;
  }
  .user-cover-container{
    height: 140px;
  }
  .sm-30{
    font-size: 30px !important;
  }
  .footer{
  padding-bottom: 90px !important;
  }
  .footer hr{
  
  margin: 0px !important;
  }
  
  .legal-section img {
    height: 55px;
  }
  .search-suggestions{
  min-width: 100% !important;
max-width: 100% !important;}
  .community-chat-offcanvas.community-chat-offcanvas{
  min-width: 100% !important;
  width: 100% !important;
  }
  .bg-theme-gradient {
    background-image: linear-gradient(6deg, #1e23ac, #000000);
}
  .college-stats{
  margin-top: 40px !important;
  }
  .nav2-items{
  padding-left: 15px;
padding-right: 15px;
  }
  .secondary-navbar .nav-item{
  display: block !important;}
  .specialization-crumb {
    background-position: right !important;
  }
  .post-action {
    height: 20px !important;
  }
  .alumni-container {
    padding-top: 10px !important;
  }
  .bottom-fixed-button {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    left: 0px;
    z-index: 99999999;
    border-radius: 0px !important;
  }
  .hacking-internship-bg {
    background-image: none;
    background: black !important;
  }
  .syllabus-section .justify-content-between {
    justify-content: center !important;
  }
  .sm-border-none {
    border: none !important;
  }
  .offcanvas-bottom {
    z-index: 9999 !important;
  }

  .blue-bg {
    min-height: fit-content !important;
  }
  .classes-cover {
    min-height: 100px !important;
  }
  .profile {
    border: none !important;
  }
  .sm-border-none {
    border: 1px solid transparent !important;
    border-color: transparent !important;
  }
  .results-driven {
    padding: 0px !important;
    background-color: white !important;
    border: none !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .reviews-horiz {
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
  }
  .hr-line-blue {
    height: 2px !important;
  }
  .slider-videos .courses-slider {
    display: flex !important;
  }
  #suggestions-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .video-content {
    padding: 0px !important;
  }
  .account-info {
    padding: 0px 10px !important;
  }
  .sm-mb-50 {
    margin-bottom: 50px;
  }
  .quiz-options-container,
  .quiz-container {
    min-height: fit-content !important;
    height: fit-content !important;
    max-height: fit-content !important;
  }
  .home-reviews .MuiRating-root {
    font-size: 1.2rem !important;
  }
  .mobile-reverse {
    flex-direction: column-reverse;
  }
  .home-reviews .userdp {
    height: 70px !important;
    width: 70px !important;
    min-height: 70px !important;
    min-width: 70px !important;
  }
  .sm-col-reverse {
    flex-direction: column-reverse;
  }
  .inputs input {
    padding: 5px !important;
  }
  .sm-text-white {
    color: white !important;
  }
  .student-pass-stats .h1 {
    font-size: 18px !important;
  }
  .pass-registration {
    display: flex !important;
  }
  .student-pass-stats h2 {
    font-size: 14px !important;
  }
  .cover-picture-edit-toggle {
    height: 35px !important;
    width: 35px !important;
    padding: 2px !important;
  }
  .cover-picture-edit-toggle svg {
    height: 20px;
    width: 20px;
  }
  .pass-cover {
    background-position: bottom !important;
    background-image: url("https://skillsuprise.com/resources/images/pass-cover-mobile.svg") !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .sm-pb-0 {
    padding-bottom: 0px !important;
  }
  .sm-18 {
    font-size: 18px !important;
  }
  .sm-20 {
    font-size: 20px !important;
  }
  .home-reviews {
    background-image: url(https://skillsuprise.com/resources/images/alumni-section-sm-crumb.svg);
  }
  .course-suggestions .cover {
    width: 80px !important;
  }
  .badge-info-card {
    min-height: fit-content !important;
    height: fit-content !important;
  }
  .sm-mt-30 {
    margin-top: 30px !important;
  }
  .sm-mt-30 {
    margin-bottom: 30px !important;
  }
  .slider-buttons {
    display: none;
  }
  .sm-truncate2 {
    -webkit-line-clamp: 2 !important;
  }
  .sm-10 {
    font-size: 10px !important;
  }
  .feature-option {
    font-size: 10px;
  }
  .feature-option .button {
    font-size: 12px !important;
  }
  .sm-14 {
    font-size: 14px !important;
  }
  .sm-mt-0 {
    margin-top: 0px !important;
  }
  .sm-12 {
    font-size: 12px !important;
  }
  .alumni-item .truncate {
    font-size: 14px;
  }
  .sm-mb-0 {
    margin-bottom: 0px !important;
  }
  .sm-text-small {
    font-size: 13px;
  }
  .sm-col-reverse {
    flex-direction: column-reverse;
  }
  .nav-items {
    min-width: fit-content !important;
    max-width: fit-content !important;
  }
  .btn-continue {
    font-size: 13px;
  }
  h1 {
    font-size: 25px !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  .course-action {
    box-shadow: 0px 0px 2px #a5a5a5;
    display: flex !important;
  }
  .logo {
    width: 150px;
    height: auto !important;
  }
  .MuiBox-root {
    padding: 0px !important;
  }
  .course-content {
    height: fit-content !important;
    min-height: fit-content !important;
  }
  .course-container {
    min-height: fit-content !important;

    border-radius: 5px;
  }
  .course-content {
    border-radius: 5px;
  }
  .slider-course-container {
    border-radius: 5px;
  }
  .course-content {
    display: flex;
  }
  .course-cover {
    border-radius: 5px 0px 0px 5px !important;
    max-height: 120px;
    max-width: 120px;
    min-height: 120px !important;
    min-width: 120px !important;
    height: 120px !important;
    width: 120px !important;
  }

  .course-title {
    font-size: 16px;
  }
  .course-content .info .MuiRating-root {
    font-size: 1.2rem;
  }
  .nav-item {
    display: none;
  }
  .profile {
    border: none !important;
  }
  .video-suggestions {
    height: auto;
    overflow: hidden;
  }
}
.auth-google-logo {
  height: 25px;
}
.muted-block{

  background-color: black;
  opacity: 0.4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
}
.sidebar-scroller{
padding-bottom: 120px !important;
}
.muted-speaker{
  display: flex;
  align-items: center;
  flex-direction: column;
padding: 15px;
background-color: black;
color: white;
justify-content: center;
}
.certification-screen{

  background-color: #ffffff59;


}
.grey-cta{
background-image: url('../grey-cta.svg');
background-size: cover;
background-position: center;
}
.highlighted-review{
  padding: 2px 5px;
  border-radius: 2px;
background-color: black;
color: orange;
}
.course-certifications{
background-color: var(--t-green1);
}
.demo-left{
background-color: var(--t-green1);}

/* CustomCalendar.css */

/* Make the calendar container rectangular */
.react-calendar {
  width:100%  !important;
  max-width: 500px; /* Adjust the width as needed */
  border-radius: 5px; /* Add rounded corners if desired */
}

/* Style the individual calendar tiles (days) */
.react-calendar__tile {
  height: 50px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add any other custom styles as needed */

.community-chat-offcanvas{

min-width: 550px !important;
}.scroll-vert::-webkit-scrollbar{
height: 0px;
width: 0px;
}
.react-calendar__month-view__days button{
height: 45px;
width: 45px ;
max-width: 45px;
max-height: 45px;
}
.react-calendar__month-view__days button{
border-radius: 50%;}
.community-chat-offcanvas .offcanvas-body{
overflow-y: hidden !important;}
.react-calendar__month-view__weekdays{
text-align: left !important;}
.react-calendar__month-view__days{
  display: grid !important;
  grid-template-columns: repeat(7, 1fr); /* Adjust the number of columns here */
  
}
.react-calendar__month-view__weekdays{
  display: grid !important;
  grid-template-columns: repeat(7, 1fr); /* Adjust the number of columns here */
  flex: none !important;
}
.secondary-navbar{
height: 40px;
position: fixed;
top: 64px;
overflow-x: auto;
left: 0px;
min-height: 40px;
color: white;
width: 100%;
z-index: 1;
}
vr{
  display: none;
  margin-left: 10px;
  margin-right: 10px;
height: 25px;
width: 1px;
background-color: grey;
}
.secondary-navbar a{
  min-width: fit-content;
margin-right: 20px;
}
.secondary-navbar::-webkit-scrollbar{
height: 0px;
width: 0px;}
.college-cta{

background-image: url();
}
.displaynavbar2{
top: 63px;
transition: 1s;
}
.hidenavbar2{
transition: 1s;
top: 10px;
}
.college-bg{

/*background-image: url('../college-bg.svg');

*/background-repeat: no-repeat;
background-size: cover;

}

.college-stats{
  z-index: 1;
  position: relative;
margin-top: 40px;
}
.secondary-navbar .overflow-x::-webkit-scrollbar{

overflow-x: hidden !important;
}
.college-options button{
min-width: fit-content !important;}

.secondary-navbar .overflow-x::-webkit-scrollbar{
height: 0px !important;
width: 0px !important;
}
.course-batch-chat{
  display: none !important;
background-color: white !important;}

.login-slider-item{
bottom:0px;
position: absolute;
left:0px;
 margin-bottom: -10px;
  
}.bg-theme-green{
background-color: #009e55 !important;
}
/*animations*/

.animation-active .slide-top {
  -webkit-animation: slide-top 0.5s ease-out 6ms both;
  animation: slide-top 0.5s ease-out 6ms both;
  animation-delay: 3s;
} /* ----------------------------------------------
* Generated by Animista on 2022-10-16 14:41:56
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-top
* ----------------------------------------
*/
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100);
    transform: translateY(100);
    -moz-transform: translateY(100);
    -ms-transform: translateY(100);
    -o-transform: translateY(100);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);

    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100);
    transform: translateY(100);
    -moz-transform: translateY(100);
    -ms-transform: translateY(100);
    -o-transform: translateY(100);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
}
.animation-active .jello-horizontal {
  animation-delay: 0.5s !important;
  transition: 0.5s !important;
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  -o-transition: 0.5s !important;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-16 15:17:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.animation-active .slide-in-right {
  -webkit-animation: slide-in-right 0.5s ease-in-out both;
  animation: slide-in-right 0.5s ease-in-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-16 15:24:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.animation-active .fadein{
  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0.7;
  }
  100% {
      opacity: 1;
  }
}
.delay1s {
  animation-delay: 0.3s !important;
}
.delay01s {
  animation-delay: 0.1s !important;
}
.delay02s {
  animation-delay: 0.2s !important;
}
.delay03s {
  animation-delay: 0.3s !important;
}
.delay04s {
  animation-delay: 0.4s !important;
}
.delay05s {
  animation-delay: 0.5s !important;
}
.delay06s {
  animation-delay: 0.6s !important;
}
.delay07s {
  animation-delay: 0.7s !important;
}
.delay08s {
  animation-delay: 0.8s !important;
}
.delay09s {
  animation-delay: 0.9s !important;
}
.delay2s {
  animation-delay: 0.5s !important;
}
.delay3s {
  animation-delay: 0.8s !important;
}
.delay4s {
  animation-delay: 1.2s !important;
}
.delay5s {
  animation-delay: 1.5s !important;
}
.delay6s {
  animation-delay: 1.8s !important;
}
.delay7s {
  animation-delay: 2.2s !important;
}
.delay8s {
  animation-delay: 2.4s !important;
}
.delay9s {
  animation-delay: 2.8s !important;
}
.delay10s {
  animation-delay: 3.2s !important;
}
.delay11s {
  animation-delay: 3.6s !important;
}
.delay12s {
  animation-delay: 4s !important;
}
.animation-active .fade-in-bottom {
  -webkit-animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;

}
.animation-active .fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-16 15:37:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.animation-active .wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-16 15:40:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

.nosecondary{

margin-top: 54px !important;
}
.min-vh-30{
  min-height: 30vh;
  
}
.slider-reel-item {
  max-width: 170px;
  min-width: 170px;
  min-height: 280px;
  height: auto;
  position: relative;
 /* Adjust width as needed */
   /* Aspect ratio 9:16 (height/width * 100%) */
  background-color: #f0f0f0; /* Background color for visibility */

}
.reel-content{

  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #ccc; /* Example content background color */
}
.reel-actions .send{
  rotate: 325deg;
}
.caption-canvas{
  min-height: 40vh;
  height: 40vh;
  background-color: transparent !important;
  margin-bottom: 50px;
  border: none !important;
}
.reel-actions p{
  color: white;
  padding: 0px ;
  margin: 0px;
  text-align: center;
  text-shadow: 0px 0px 3px black;
}
.caption-inner{
  width: calc(100% - 30px);
}
.dark-bottom-nav{
  background-color:black !important;

}
.dark-bottom-nav p, .dark-bottom-nav svg{
  color: white !important;
}
.dark-bottom-nav .active p, .dark-bottom-nav .active svg{
  color: grey !important;
}

.dark-bottom-nav .active{
  border-top: 3px solid white !important;
}
.slider-reel-item{
  min-height: 250px;
}


/* about company */

.hover:hover {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 16px rgb(124, 124, 124);
}

.bg-img {
  background-image: url(../about/images/ratings.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img2 {
  background-image: url(../about/images/image6.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Add to your CSS file or <style> tag */
@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}





#demodiv {
  border-radius: 70px 4px 4px 4px;
}

#demodiv .circle {

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transform: translateY(200px);
  transition: all 1.5s;
}

#demodiv:hover .circle {

  z-index: 1;
  transform: scale(10);
  background-color: rgb(56, 56, 192);
  opacity: 0.5;
  visibility: visible;
}

#hidden_text {
  z-index: -1;
  transition: all 0.1s;
  transition-delay: 0.3s;
}

/* #hidden_text h1{
/* backdrop-filter: brightness(1.5); 
} */
#demodiv:hover #hidden_text {
  z-index: 100;
}

#demodiv:hover img {
  transform: scale(1.4);
}

#demodiv img {
  transition: all 1s;
  transition-delay: 0.3s;
}

#circle1 {
  height: 100px;
  width: 100px;
  top: 100px;
  left: 308px;
  border-radius: 53px;
  background-color: rgb(56, 56, 192);
  visibility: hidden;
}

#demodiv1 {
  /* height: 321px; */
  border: 3px solid rgb(0, 180, 251);
  margin: 10px 0 10px 0;
  transition: 0.5s;
  box-shadow: 0 0 5px gray;
}

#demodiv1:hover {

  border: none;
  box-shadow: 0 0 10px gray;
}


#demodiv1 #name {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.873);
}

#hidden_div {
  background-image: linear-gradient(60deg, rgb(254, 58, 143), rgb(33, 73, 253) 50%);
  opacity: 0.9;
  z-index: 1;
  /* height: 72%; */
  transition: all 1.5s;
}

#demodiv1:hover #hidden_div {
  transform: translateY(-372px);
}

#hidden_div button {
  font-weight: 700;
}

#hidden_div img {
  width: 0px;
  margin: 10px;
  background-color: white;
  border-radius: 27px;
  padding: 4px;
}

#hidden_div p {
  margin: 0px;
  color: white;
}

.fnt {
  font-size: 19px;
}

.sdw {
  transition: 0.5s;
}

.sdw:hover {
  box-shadow: 0 0 20px gray !important;
  /* transition: scale 3s; */
}

.zoom {
  transition: scale 3s;
}

.zoom:hover {
  scale: 120%;
}

/* .marquee{
  position: relative;
} */

.marquee:before, .marquee:after{
  position: absolute;
  top: 0;
 height: 100%;
  width: 10rem; 
  content: "";
  z-index: 1;
}

 .marquee:before{
  left: 0;
  background: linear-gradient(to right, #ffffffe7 0%, transparent 20%);
}
.marquee:after{
  right: 0;
  background: linear-gradient(to left, #ffffffee 0%, transparent 20%);
} 

.rtl:hover{
animation-play-state: paused;
}

.rtl {
  white-space: nowrap;
  animation: move-rtl 10000ms linear infinite;
}

@keyframes move-rtl {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.ltr:hover{
animation-play-state: paused;
}

.ltr {
  white-space: nowrap;
  animation: move-ltr 10000ms linear infinite;
}

@keyframes move-ltr {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  position: relative;
  animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
}

.marquee img {
  display: inline-block;
}


.txt{
  color: rgba(128, 128, 128, 0.681);
}
.grn{
  background-color: rgb(174, 248, 174);
  color: rgb(56, 131, 56);
  font-size: 13px;
}

 .text-bg{
  background-color: rgb(240, 240, 240);
 }

 .bdr{
  border-right: 2px solid rgb(185, 185, 185);
  
 }

 .clr{
  background-color: rgb(235, 240, 251);
 }

 .overflow{
  overflow-y: scroll;
  height: 500px;
 }

 .hgt{
  height: 300px;
  overflow-y: scroll;
  background-color: white;

 }
 .hgt::-webkit-scrollbar{
display: none;
 }

 .bg-gr{
  background-color: rgb(61, 254, 61);

 }

 .thumbnail-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* This creates the 4:3 aspect ratio (3/4 = 0.75) */
  overflow: hidden;
}

.thumbnail-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the container */
}
.dashboard-placements .item{
  height: 90px;
  min-width: 85px;
  margin-right: 10px;
  min-height: 90px;
  width: 85px;
  background-color: black;
}
.popular-item{
  margin-bottom: 5px;
  min-height: 80px;
  border-radius: 10px;
  background-color: black;
}
.dashboard-review-image{
  height: auto;
  min-height: 300px;width: 100%;
  background-color: black;
}
.cover-actions{
  right: 0px;
  bottom:00px;
  position: absolute;
}
.cover-actions a{

  box-shadow: 0px 0px 3px lightgrey;

  font-weight: normal;
}
.dashboard-cta{
  background: #081a77 !important;
}
.reels-desktop-actions svg{


  height: 40px;
  width: 40px;

  color: black;
  border:1px solid black;
  padding: 10px;
  border-radius: 50%;
}
.reels-desktop-actions{
  position: absolute;
  right: -80px;
  top: 45%;


}
.upcoming-batches-light{
  background-color: transparent !important;
  border: 1px solid light !important;
  color: white !important;
}
.upcoming-batches-light button{
  color: white !important;
  padding: 0px !important;
}
.upcoming-batches-light *{
  font-size: 12px !important;
}
.right-10{
right: 10px !important;
}
.bottom-10{
bottom: 10px !important;}

@media only screen and (max-width: 767px) {
  .border-btm{
    border-bottom: 1px solid black;
    } 
    .md-rgt{
    border-right: 0px !important;
    }
}
.min-vh-20{
  min-height: 20vh !important;
}
.min-vh-30{
  min-height: 30vh !important;
}
/* @media screen with  {
  
}
 */