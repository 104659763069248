.profile1{
    background-color: rgb(225, 225, 223);
    border-radius: 6px;
    border-style: solid;
    border-color: rgb(194, 193, 193);
    
}
.profile2{
    background-color: #fef1d3;
    border-radius: 6px;
    border-style: solid;
    border-color: #fbdf9d;
}

.profile3{
    background-color: rgb(214, 233, 250);
    border-radius: 6px;
    border-style: solid;
    border-color: rgb(177, 217, 252);
}
.profile4{
    background-color: rgb(198, 244, 196);
    border-radius: 6px;
    border-style: solid;
    border-color: rgb(150, 250, 147);
}
.profile5{
    background-color:#ebc9f7;
    border-radius: 6px;
    border-style: solid;
    border-color: #dc97f6;
}
.icon{
    background: none;
}
.bg-none{
    background-color:rgb(100, 119, 246); ;
}
.bg-wapp{
    background-color: rgb(68, 165, 76);;
}
.img_res{
    width:100%;
    height:auto;
}