

/*--------------------------------------------------------------------- File Name: style.css ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- import Fonts ---------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,400,600,700,900&display=swap');

/*****---------------------------------------- 1) font-family: 'Rajdhani', sans-serif;
 2) font-family: 'Poppins', sans-serif;
 ----------------------------------------*****/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/

@import url(theme-defaults.css);


/*--------------------------------------------------------------------- skeleton ---------------------------------------------------------------------*/

* {
     box-sizing: border-box !important;
}
@media (prefers-reduced-motion: no-preference) {
     .App-logo {
       animation: App-logo-spin infinite 20s linear;
     }
   }
   
   @keyframes App-logo-spin {
     from {
       transform: rotate(0deg);
     }
     to {
       transform: rotate(360deg);
     }
   }
   
html {
     scroll-behavior: smooth;
}

body {
     background: #fff;
     color: #090909;
     font-size: 14px;
     font-family: 'Raleway', sans-serif;
     line-height: 1.80857;
     font-weight: normal;
}

.container {
     max-width: 1172px;
}

a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
     letter-spacing: 0;
     font-weight: normal;
     position: relative;
     padding: 0 0 10px 0;
     font-weight: normal;
     line-height: normal;
     color: #090909;
     margin: 0
}

h1 {
     font-size: 24px;
     font-family: 'Raleway', sans-serif;
}

h2 {
     font-size: 22px;
}

h3 {
     font-size: 18px;
}

h4 {
     font-size: 16px
}

h5 {
     font-size: 14px
}

h6 {
     font-size: 13px
}.btn-orange-red{
color: white !important;
background-color: rgb(255, 0, 17) !important;
}
.animate .fade-in-left {
     opacity: 0;
     transform: translateX(-20px); /* Adjust the distance as needed */
   
     animation: fadeInLeft 1s ease-in-out forwards;
   }
   
   @keyframes fadeInLeft {
     from {
       opacity: 0;
       transform: translateX(-20px);
     }
   
     to {
       opacity: 1;
       transform: translateX(0);
     }
   }
.workshop-progress .MuiCircularProgress-root{
     height: 20px !important;
     width: 20px !important;
     font-size: 14px !important;
     margin-right: 5px;
}
.class-date-box{

     height: 60px;
     width: 60px;
     
     border-radius: 4px;
     margin-right: 7px;
     min-width: 60px;
     background-color: black;
}
.dashboard-class-item{
     margin-bottom: 10px;
}
.rec-suggestion{
     background-repeat:no-repeat;
     background-size: cover;
     background-position: center;
}
.suggestion-cover{
     text-align: center;
     background-color: #0000007d;
}
.suggestion-cover *
{
     padding: 0px;
}
.min-vh-30{
     min-height: 30vh;
}
.w-fit{
     min-width: fit-content !important;
}
.grey-body{
     background-color: #e3e3e369;
}
#sidebar{
  background-color: #000000b8 !important;
}
.search-backdrop{
  height: 100vh;
  width: 100%;
  background-color:#000000b0 !important;
  position: fixed;
  top: 0px;
  left: 0px;
}
@media only screen and (max-width:576px){

    .grey-body{
     background-color: white !important;
    }
}
.search-backdrop{
     height: 100vh;
     width: 100%;
     background-color:#000000b0;
     position: fixed;
     top: 0px;
     left: 0px;
}
.w-fit{
     min-width: fit-content !important;
     width: fit-content !important;
}
.class-date-box .time{
     font-size: 12px;
}
.resource-cover{
     height: 80px;
     min-width: 120px;
     width: 120px;
     border: 1px solid grey;
}
.summer-internship-banner{
     background-size: cover;
     min-height: 600px;
     height: 600px;
     background-repeat: no-repeat;
     background-image: url('https://skillsuprise.com/data/images/summer-internship.png')
}
.plan-bg{
     background-color: #070086 !important;
}
.summer-internship-banner .banner-image{

     position: absolute;
     left: 0px;
     z-index: inherit;
     height: 600px;
     min-height: 600px;
     top: 0px;
}.background-banner{

     background-color: #0000008f;
height: fit-content;
     z-index: 9999;
}
.overflow-y-scroll{
     overflow-y: scroll;
}
.overflow-y-scroll::-webkit-scrollbar{
     width: 0px;
     height: 0px;
}
.vh-80{
     height: 80vh;
}
.border-dashed{
     border:  1px dashed #b0b0b0;
}

/* course details */
/* .bgdark{
     background: linear-gradient(90deg, rgb(33 37 41) 10%, #024eb8 100%);
}
.background{
     background: linear-gradient(90deg, rgb(255, 207, 160) 0%, rgba(233, 93, 93, 0) 99.69%);
   }
  .fs{
     font-size:15px;
  }
*/
  .invisible-scrollbar::-webkit-scrollbar {
     display: none;
   } 

   .ass-description {
     overflow: hidden;
     max-height: 0;
     transition: max-height 300ms ease-out;
   }
   
   .ass-description.show {
     max-height: 200px; /* Adjust based on your content */
     transition: max-height 300ms ease-in;
   }

   /* .overflow::-webkit-scrollbar{
     display:none;
   } */

 .flow{
     overflow-y: scroll;
 }

 .flow::-webkit-scrollbar{
     display:none
 }
 
 .over{
     overflow-y: scroll;
 }

 /* gallery */

 .case-image {
     position: relative;
    }
    .hidden-case-1 {
      display: none;
      position: absolute;
      bottom: 0;
      padding: 15px;
      color: #fff;
      border-radius: 5px;
      text-align: center;
      box-sizing: border-box;
      background-image: linear-gradient(360deg,blue,rgba(5, 32, 233, 0));
     }
    .case-image:hover .hidden-case-1{
      display: block;
      animation: case 0.5s;
    }
    
    @keyframes case{
      0%{
         height: 0%;
      }
      100%{
         height: 60%;
      }
    }

   
   /* about company */

   .hover:hover {
     background-color: white;
     border-radius: 15px;
     box-shadow: 0 0 16px rgb(124, 124, 124);
   }
   
   .bg-img {
     background-image: url(../about/images/ratings.jpeg);
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
   }
   
   .bg-img2 {
     background-image: url(../about/images/image6.png);
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
   }
   
   /* Add to your CSS file or <style> tag */
   @keyframes scroll-left {
     0% {
       transform: translateX(100%);
     }
   
     100% {
       transform: translateX(-100%);
     }
   }
   
   .marquee {
     display: flex;
     overflow: hidden;
     white-space: nowrap;
   }
   

   .marquee-reverse img {
     animation: scroll-left 10s linear infinite reverse;
   }
   
   #demodiv {
     border-radius: 70px 4px 4px 4px;
   }
   
   #demodiv .circle {
   
     display: flex;
     align-items: center;
     justify-content: center;
     color: white;
     transform: translateY(200px);
     transition: all 1.5s;
   }
   
   #demodiv:hover .circle {
   
     z-index: 1;
     transform: scale(10);
     background-color: rgb(56, 56, 192);
     opacity: 0.5;
     visibility: visible;
   }
   
   #hidden_text {
     z-index: -1;
     transition: all 0.1s;
     transition-delay: 0.3s;
   }
   
   /* #hidden_text h1{
   /* backdrop-filter: brightness(1.5); 
   } */
   #demodiv:hover #hidden_text {
     z-index: 100;
   }
   
   #demodiv:hover img {
     transform: scale(1.4);
   }
   
   #demodiv img {
     transition: all 1s;
     transition-delay: 0.3s;
   }
   
   #circle1 {
     height: 100px;
     width: 100px;
     top: 100px;
     left: 308px;
     border-radius: 53px;
     background-color: rgb(56, 56, 192);
     visibility: hidden;
   }
   
   #demodiv1 {
     /* height: 321px; */
     border: 3px solid rgb(0, 180, 251);
     margin: 10px 0 10px 0;
     transition: 0.5s;
     box-shadow: 0 0 5px gray;
   }
   
   #demodiv1:hover {
   
     border: none;
     box-shadow: 0 0 10px gray;
   }
   
   
   #demodiv1 #name {
     bottom: 0px;
     background-color: rgba(0, 0, 0, 0.873);
   }
   
   #hidden_div {
     background-image: linear-gradient(60deg, rgb(254, 58, 143), rgb(33, 73, 253) 50%);
     opacity: 0.9;
     z-index: 1;
     /* height: 72%; */
     transition: all 1.5s;
   }
   
   #demodiv1:hover #hidden_div {
     transform: translateY(-372px);
   }
   
   #hidden_div button {
     font-weight: 700;
   }
   
   #hidden_div img {
     width: 0px;
     margin: 10px;
     background-color: white;
     border-radius: 27px;
     padding: 4px;
   }
   
   #hidden_div p {
     margin: 0px;
     color: white;
   }
   
   .fnt {
     font-size: 19px;
   }
   
   .sdw {
     transition: 0.5s;
   }
   
   .sdw:hover {
     box-shadow: 0 0 20px gray !important;
     /* transition: scale 3s; */
   }
   
   .zoom {
     transition: scale 3s;
   }
   
   .zoom:hover {
     scale: 120%;
   }
   
   /* .marquee{
     position: relative;
   } */
   
   .marquee:before, .marquee:after{
     position: absolute;
     top: 0;
    height: 100%;
     width: 10rem; 
     content: "";
     z-index: 1;
   }
   
    .marquee:before{
     left: 0;
     background: linear-gradient(to right, #ffffffe7 0%, transparent 20%);
   }
   .marquee:after{
     right: 0;
     background: linear-gradient(to left, #ffffffee 0%, transparent 20%);
   } 
   
   .rtl:hover{
   animation-play-state: paused;
   }
   
   .rtl {
     white-space: nowrap;
     animation: move-rtl 10000ms linear infinite;
   }
   
   @keyframes move-rtl {
     0% {
       transform: translateX(-100%);
     }
   
     100% {
       transform: translateX(0%);
     }
   }
   
   .ltr:hover{
   animation-play-state: paused;
   }
   
   .ltr {
     white-space: nowrap;
     animation: move-ltr 10000ms linear infinite;
   }
   
   @keyframes move-ltr {
     0% {
       transform: translateX(0%);
     }
   
     100% {
       transform: translateX(-100%);
     }
   }
   
   .marquee {
     white-space: nowrap;
     overflow: hidden;
     display: inline-block;
     position: relative;
     animation: marquee 30s linear infinite;
     -webkit-animation: marquee 30s linear infinite;
   }
   
   .marquee img {
     display: inline-block;
   }
   
   
   .txt{
     color: rgba(128, 128, 128, 0.681);
   }
   .grn{
     background-color: rgb(174, 248, 174);
     color: rgb(56, 131, 56);
     font-size: 13px;
   }
   
    .text-bg{
     background-color: rgb(240, 240, 240);
    }
   
    .bdr{
     border-right: 2px solid rgb(185, 185, 185);
     
    }
   
    .clr{
     background-color: rgb(235, 240, 251);
    }
   
    .overflow{
     overflow-y: scroll;
     height: 500px;
    }
   
    .hgt{
     height: 300px;
     overflow-y: scroll;
     background-color: white;
   
    }
    .hgt::-webkit-scrollbar{
   display: none;
    }
   
    .bg-gr{
     background-color: rgb(61, 2
     
     .6
     9+4, 61);
    }
   
 .reel-item{
  height: calc(100vh - 50px);
  background-color:black;

 }

html {
  scroll-snap-type: y mandatory;
}

.App {
  display: grid;
  place-items: start;
  height: 100vh;
  background-color: rgb(26, 25, 25);
  color: #fff;
}



.video-container {
  position: relative;
  height: calc(100vh - 50px);
  width: 100%;
  max-width: 100%;

  background-color: #fff;
  overflow: scroll;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}


@media (max-width: 992px) {
  .video-container {
    position: relative;
    height: auto !important;
    width: 100%;
    max-width: 100%;

    background-color: #fff;
    overflow: scroll;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
  }
}

/* for chrome and opera */
.video-container::-webkit-scrollbar {
  display: none;
}
.reel-item video{
  width: 100%;
  scroll-snap-align: start;
  height: 100%;
}
.reel-item{
  height: calc(100vh - 50px);
  position: relative;
}
.reels-list{
  display: grid;
    place-items: start;
    height: 100vh;
    background-color: #1a1919;
    color: #fff;

}
.reel-actions{
  position: absolute;
  bottom: 60px;
  right: 10px;
  display: flex;
  flex-direction: column;
  
  justify-content:center;

}
.reel-title{
  position:absolute;
  bottom: 10px;
  left: 0px;
  padding: 10px;
  width: 100%;
}
.dashboard-placements .bg-dark{
  border-top-left-radius:  0px !important;
  border-top-right-radius: 0px !important;
}
.dashboard-placements img{
  border-bottom-left-radius:  0px !important;
  border-bottom-right-radius: 0px !important;
}

.badges {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

