@media only screen and (max-width:576px){
    .home-search{
        margin-top: 20px !important;
    }
    .college-stats{
        border-radius: 0px !important;
    }
    .container{
        padding-left: 1.3rem !important;
        padding-right: 1.3rem !important;
    }
    .sm-br-0{
        border-radius: 0px !important;
    }
    .sm-mt-10{
        margin-top: 10px !important;

    }
    .sm-mb-10{
        margin-bottom: 10px !important;
    }
    .sm-mt-20{
        margin-top: 20px !important;
    }
    .sm-mb-20{
        margin-bottom: 20px !important;
    }
    .sm-mb-50{
        margin-bottom: 50px !important;
    }
    .sm-pt-10{
        padding-top: 10px !important;
    }
    .sm-pt-15{
        padding-top: 15px !important;
    }
    .sm-pt-20{
        padding-top: 20px !important;
    }
    .sm-mt-30{
        margin-top: 30px !important;
    }
    .sm-pt-30{
        padding-top: 30px !important;
    }
    .sm-pt-40{
        padding-top: 40px !important;
    }
    .sm-mt-50{
        margin-top: 50px !important;
    }
    .sm-mt-30{
        margin-top: 30px !important;
    }
    .sm-mx-auto{
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .btn-workshop-register{
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #0d6efd;
        z-index: 999999;
    }
    .internship-item h6{
        font-size: 14px;
    
    }
    .sm-col-reverse{
        flex-direction: column-reverse;
    }
    .sm-mb-50{
        margin-bottom: 50px !important;
    }
    .sm-pb-50{
        padding-bottom: 50px !important;
    }
    h5{
        font-size: 16px !important;
    }
    h3{
        font-size: 20px !important;
    }
    h2{
        font-size: 22px !important;
    }
    .sm-p-0{
        padding: 0px !important;
    }
    .sm-pt-0{
        padding-top: 0px !important;
    }
    .sm-text-center{
        text-align: center;
    }
    .sm-text-left{
        text-align: left !important;
    }
    .sm-shadow-none{
        box-shadow: none !important;
    }
    .sm-16{
        font-size: 14px !important;
    }
    .course-progress-cover{
        height: 100px;
    }
    .workshop-suggestion-cover{
        height: 120px;
    }
    .sm-14{
        font-size: 14px !important;
    }
    .sm-pb-60{
        padding-bottom: 60px !important;
    }
    .sm-pb-30{
        padding-bottom: 30x !important;
    }
    .profile .userdp{
        min-height: 80px !important;
        width: 80px !important;
   
   min-width: 80px !important;
   height: 80px !important;
    }

    .sm-13{
        font-size: 13px;
    }
    h4{
        font-size: 18px;
    }

    .view-comment-text{
        font-size: 13px;
    }
    .sm-p-15{
        padding: 15px !important;
        
    }
    .sm-px-5{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }.comments-page .post-comment{
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
    }
    .course-content .course-cover{
        max-height: 115px !important;
        min-height: 115px !important;
    }
    .workshop-crumb2{
        height: fit-content !important;
    }
    .courses-slider .p-3{
   
        padding: 0.2rem !important;
    }
  
    .course-content{
        max-height: 115px;
    }
    .comments-page .post-footer{
        box-shadow: none !important;
    }
    .workshop-banner{
        background-image: url('../recruiter-bg.svg') !important;
    }
}
.pt-50{
    padding-top: 50px;
}
.btn-border-dashed{
    border-radius: 5px;
    border: 1px dashed grey;
    background-color: transparent;

}
.btn-comment{
    background-color: transparent;
    color: rgb(13, 13, 147);
    border:none;
    height:40px;
    min-width: 70px;
}
.click{
    cursor: pointer;
}
.post-item input{
    border: none;
    padding-left: 10px;
}
.post-item:focus{
    border: none;
}tbody, td, tfoot, th, thead, tr{
    height: fit-content !important;
}
h5{
    font-weight: 500 !important;
  }
  .title-overlay {
    bottom: 0%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    background-color: rgba(0, 0, 0, 0.636); 
    padding: 5px 10px;
    z-index: 1;
    position: absolute; 
    text-align: center;
    border-radius: 5px; 
  }
  
  .case-image {
    position: relative; 
  }
  
  .case-image img {
    position: relative;
    z-index: 0;
  }

  .fnt-10{
font-size: 10px;
}
